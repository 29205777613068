import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../assets/tom-logo.png';
import { Typography, Button } from '../../components';
import * as S from './ErrorPage.styles';
import { META_TITLE } from '../../../constants/general.constants';

const ErrorPage = ({ message, isError }) => {
	const history = useHistory();

	useEffect(() => {
		document.title = `Page Not Found | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Town of Morrisville Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
				<Typography tag="h2" weight="bold" center>
					{isError ? 'Uh oh, looks like there was an error' : "Uh oh, we can't seem to find the page you're looking for"}
				</Typography>
				<Typography tag="p" className="description" center>
					Try going back to the previous page or contact us for help.
				</Typography>
				{message && (
					<Typography tag="p" className="error-message" center>
						{`Error: ${message}`}
					</Typography>
				)}
				<Button type="submit" className="submit-button" onClick={() => history.push('/')}>
					<Typography variation="button-medium" weight="bold">
						Go to Home
					</Typography>
				</Button>
			</S.Container>
		</S.Wrapper>
	);
};

export default ErrorPage;

ErrorPage.displayName = 'ErrorPage';
ErrorPage.propTypes = {
	message: PropTypes.string,
	isError: PropTypes.bool
};
