import React, { useEffect } from 'react';
import logo from '../../assets/tom-logo.png';
import {
	Typography
} from '../../components';
import * as S from './DeleteAccountInstructions.styles';
import { META_TITLE } from '../../../constants/general.constants';

const DeleteAccountInstructions = () => {

	useEffect(() => {
		document.title = `Delete Account | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Town of Morrisville Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h2" weight="bold" center>
					Instructions to delete your Morrisville Central account
				</Typography>
				<Typography tag="p" className="description" center>
					<ol>
						<li>
							Open the
							{' '}
							<a href="https://play.google.com/store/apps/details?id=org.townofmorrisville.morrisville&hl=en_US&gl=US" target="_blank" rel="noreferrer">Morrisville Central</a>
							{' '}
							mobile app on your device
						</li>
						<li>
							Log into your Morrisville Central account
						</li>
						<li>
							In the top left corner of the app, tap the
							{' '}
							<strong>Gear icon</strong>
							{' '}
							(Settings)
						</li>
						<li>
							On the Settings screen, under
							{' '}
							<strong>Account Settings</strong>
							, tap the
							{' '}
							<strong>Profile</strong>
							{' '}
							option
						</li>
						<li>
							At the bottom of the screen, tap the
							{' '}
							<strong>Delete Account</strong>
							{' '}
							option and confirm to delete your account
						</li>
					</ol>
				</Typography>
			</S.Container>
		</S.Wrapper>
	);
};

export default DeleteAccountInstructions;
