import { configureStore } from '@reduxjs/toolkit';

import menuReducer from './slices/menu/menuSlice';
import userReducer from './slices/user/userSlice';


export default configureStore({
	reducer: {
		menu: menuReducer,
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});
