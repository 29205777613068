import axios from 'axios';
import toast from 'react-hot-toast';

import { getCookie } from '../utils/cookie-methods';

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async (config) => {
	const token = getCookie('e_s_token');

	const headers = { ...config.headers };
	headers['x-parse-application-id'] = process.env.REACT_APP_APP_ID;
	if (token) {
		headers['x-parse-session-token'] = token;
	}
	return { ...config, headers };
});

export const errorHandler = (error) => {
	toast.error(error?.response?.data?.error || error?.response?.data?.message || 'Something went wrong, try again later!');

};

export default api;
