// COPYRIGHT TOWN OF MORRISVILLE

/**
 * Roles
 */

exports.ROLES = {
	STANDARD: 'standard'
};

/**
 * General
 */

exports.DEFAULT_TIMEZONE = 'America/New_York';
exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
exports.META_TITLE = 'Town of Morrisville';

/**
 * Cookies
 */

exports.SESSION_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
exports.SESSION_TOKEN_COOKIE = 'e_s_token';

/**
 * Cookie Domain
 */

exports.COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'staging':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'production':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		default:
			return null;
	}
};

/**
 * File Upload Limits (Bytes)
 */

exports.FILE_UPLOAD_LIMITS = {
	PROFILE_MEDIA: 10000000, // 10 MB
	RESOURCE_MEDIA: 10000000, // 10 MB
};

/**
 * Acceptable File Types
 */

exports.ACCEPTABLE_FILE_TYPES = {
	PROFILE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	}
};

/**
 * State Options
 */

exports.STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

exports.STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];

/**
 * Days of Week
 */

exports.DAYS_OF_WEEK = [
	'sun',
	'mon',
	'tue',
	'wed',
	'thu',
	'fri',
	'sat'
];

/**
 * Amenity Types
 */

exports.AMENITY_TYPES = {
	COURTS: {
		id: 'COURTS',
		name: 'Courts',
		lookups: []
	},
	TENNIS_COURTS: {
		id: 'TENNIS_COURTS',
		name: 'Tennis Courts',
		lookups: [
			'tenniscourt',
		]
	},
	PICKLEBALL_COURTS: {
		id: 'PICKLEBALL_COURTS',
		name: 'Pickleball Courts',
		lookups: [
			'pickleballcourt',
		]
	},
	ELECTRIC_CHARGERS: {
		id: 'ELECTRIC_CHARGERS',
		name: 'Electric Chargers',
		lookups: [
			'electriccharger',
			'electric'
		]
	},
	PARKS: {
		id: 'PARKS',
		name: 'Parks',
		lookups: [
			'park'
		]
	},
	GATES: {
		id: 'GATES',
		name: 'Gates',
		lookups: [
			'gate'
		]
	},
	FIELDS: {
		id: 'FIELDS',
		name: 'Fields',
		lookups: [
			'field'
		]
	},
	POOLS: {
		id: 'POOLS',
		name: 'Pools',
		lookups: [
			'pool'
		]
	}
};


/**
 * Available Locations
 */

exports.AVAILABLE_LOCATIONS = [
	{
		id: 'c7a1d85f-8168-4e64-8e38-116a0624bfea',
		name: 'Cedar Fork District Park',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-cedar-fork-district-park.jpg`,
		lookups: [
			'CFDP'
		]
	},
	{
		id: '91474e36-76c5-4d59-a8da-2998ab14a4bf',
		name: 'Morrisville Aquatic and Fitness Center',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-morrisville-aquatics-fitness-center.jpg`,
		lookups: [
			'MAFC',
			'1:12576041'
		]
	},
	{
		id: 'ba0a6f3f-e265-43d1-aaf8-ff21d43d3944',
		name: 'Church Street Park',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-church-street-park.jpg`,
		lookups: [
			'CSP',
			'1:13119071',
			'1:13119081'
		]
	},
	{
		id: 'c7a1d85f-8168-4e64-8e38-116a0624bfeb',
		name: 'Morrisville Community Park',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-morrisville-community-park.jpg`,
		lookups: [
			'MCP',
			'1:14197841',
			'1:14198231'
		]
	},
	{
		id: '1:141977411:14160341',
		name: 'Town Hall',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-town-hall.jpg`,
		lookups: [
			'1:14197741',
			'1:14160341',
			'TOWNHALL1',
			'TOWN HALL 2'
		]
	},
	{
		id: '1:15343851',
		name: 'Fire Station 3',
		image: `${process.env.EXTERNAL_CDN_URL}/assets/location-fire-station-3.jpg`,
		lookups: [
			'1:15343851',
			'FS3'
		]
	}
];
