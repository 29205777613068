import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { loginUser } from '../../services/authService';
import { errorHandler } from '../../services/api';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import logo from '../../assets/tom-logo.png';
import {
	Typography, Button, TextInput
} from '../../components';
import * as S from './LoginForm.styles';
import { META_TITLE, PASSWORD_REGEX } from '../../../constants/general.constants';

const LoginForm = () => {

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm();

	useEffect(() => {
		document.title = `Log In | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		await loginUser({ email: data.email, password: data.password });
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Town of Morrisville Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h2" weight="bold" center>
					Log In
				</Typography>
				<Typography tag="p" className="description" center>
					Enter your credentials to access your dashboard.
				</Typography>
				<S.Form onSubmit={onSubmit}>
					<TextInput
						label="Email"
						id="email"
						error={errors.email}
						autoComplete="email"
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<TextInput
						label="Password"
						type="password"
						id="password"
						error={errors.password}
						autoComplete="password"
						{...register('password', {
							required: required('Password'),
							pattern: pattern('Password', PASSWORD_REGEX, 'Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long'),
						})}
					/>
					<S.ActionsWrapper>
						<Button type="submit">
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Log In'}
							</Typography>
						</Button>
					</S.ActionsWrapper>
				</S.Form>
			</S.Container>
		</S.Wrapper>
	);
};

export default LoginForm;
