import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter, Switch
} from 'react-router-dom';
import toast from 'react-hot-toast';
import { PublicRoute } from './navigation/route-components';
import {
	ForgotPasswordForm,
	ResetPasswordForm,
	ErrorPage,
	Logout,
	DeleteAccountInstructions,
	LoginForm
} from './pages';
import { fetchUser } from './app/slices/user/userSlice';
import { Spinner } from './components';

const Router = () => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const error = useSelector((state) => state.user.error);
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchUser());
		}
		if (status === 'failed' && error) {
			toast.error(error);
		}
	}, [user, error, status, dispatch]);

	return (
		<BrowserRouter>
			{status === 'loading' ? <Spinner /> : (
				<Switch>
					<PublicRoute exact path="/forgot-password" component={ForgotPasswordForm} />
					<PublicRoute exact path="/reset-password/:aToken/:bToken" component={ResetPasswordForm} />
					<PublicRoute exact path="/logout" component={Logout} />
					<PublicRoute exact path="/legal/delete-account" component={DeleteAccountInstructions} />
					<PublicRoute exact path="/" component={LoginForm} />
					<PublicRoute component={ErrorPage} />
				</Switch>
			)}
		</BrowserRouter>
	);
};

export default Router;
