// Polyfill IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';

// Configuration
import { loadIcons } from './fonts/font-awesome-icon-loader';
import Router from './Router';
import GlobalStyles from './styles/global';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

ReactModal.setAppElement('#root');
loadIcons();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ErrorBoundary>
				<GlobalStyles />
				<Router />
				<Toaster />
			</ErrorBoundary>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
