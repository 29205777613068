import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}

	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		display: block;
		width: 100%;
	}

	.react-datepicker__input-container {
		input {
			border: 0;
			background-color: ${colors.neutralForm};
			min-height: 2.8125rem;
			appearance: none;
			padding: 0 0.75rem;
			color: black;
			line-height: 'normal';
			width: 100%;
			outline: none;
			font-size: 1rem;

			&:focus {
				border-color: ${colors.neutralButton};
			}

			&:read-only {
				background-color: ${colors.neutralBackground};
				border-color: ${colors.neutralForm};
			}

			${({ withIcon }) => withIcon
				&& css`
					padding-left: 2.25rem;
				`}

			${({ error }) => error
				&& css`
					color: ${colors.stateDanger};
					border-color: ${colors.stateDanger};
					margin-bottom: .75rem;
					:focus {
						border-color: ${colors.stateDanger};
					}
				`}
		}
	}



	.date-input-popper {
		width: 100%;

		.react-datepicker {
			border: none;
			border-radius: 0;
			box-shadow: ${colors.lightShadow};
			font-family: 'Open Sans', sans-serif;
			min-width: 18.875rem;

			.react-datepicker__month-container {
				width: 100%;

				.react-datepicker__month {
					margin-top: 0;

				}

				.react-datepicker__day {
					width: 2.25rem;
					line-height: 2.25rem;
					font-size: .875rem;

					&:hover {
						border-radius: 500vh;
						width: 2.25rem;
						height: 2.25rem;
					}
				}

				.react-datepicker__day--selected {
					background-color: ${colors.brandPrimary};
					border-radius: 500vh;
					width: 2.25rem;
					height: 2.25rem;
				}

				.react-datepicker__day--outside-month {
					color: ${colors.neutralDisabled}
				}
			}


		}
		.react-datepicker__triangle {
			display: none !important;
		}

		.react-datepicker__header {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom: 0;
			background-color: #FFFFFF;

			.react-datepicker__current-month {
				font-weight: semibold;
			}

			.react-datepicker__day-name {
				color: ${colors.neutralText};
				width: 2.25rem;
				line-height: 2.25rem;
				font-size: .875rem;
			}

		}

		.react-datepicker__navigation--next,  .react-datepicker__navigation--previous {
			width: 15px;
			height: 15px;
			border: none;
		}
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: .5rem;
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	right: 0;
	margin-top: -2rem;
	margin-right: 1.875rem;
	height: 100%;
	pointer-events: none;

	svg {
		color: ${colors.neutralText};
		height: 1.25rem;
	}
`;
