import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	resetPassword,
	validateResetAttempt
} from '../../services/authService';
import { errorHandler } from '../../services/api';
import { pattern, required } from '../../utils/form-default-errors';
import logo from '../../assets/tom-logo.png';
import { Typography, Button, TextInput } from '../../components';
import * as S from './ResetPasswordForm.styles';
import { META_TITLE, PASSWORD_REGEX } from '../../../constants/general.constants';

const ResetPasswordForm = () => {
	const { aToken, bToken } = useParams();
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm();
	const history = useHistory();

	const [isValidated, setIsValidated] = useState(false);
	const [validationError, setValidationError] = useState(null);

	useEffect(() => {
		document.title = `Reset Password | ${META_TITLE}`;

		let mounted = true;
		validateResetAttempt({ aToken, bToken }).then((response) => {
			const { success, error } = response.data.result;
			if (mounted) {
				setIsValidated(true);
				setValidationError(success !== true ? error : null);
			}
		});
		return () => { mounted = false; };
	}, []);

	async function asyncCaller(data) {
		await resetPassword({ aToken, bToken, pass: data.newPassword });
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="Town of Morrisville Logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			{ (() => {
				if (isValidated !== true) {
					return <div />;
				} if (isSubmitSuccessful === true) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'check-circle']} size="3x" />
							<Typography tag="h2" weight="bold" center>
								Password Reset Complete
							</Typography>
							<Typography tag="p" className="description" center>
								Your Password has been reset. Continue to login to access your account.
							</Typography>

							<Button type="submit" className="submit-button" onClick={() => { window.location = 'https://www.townofmorrisville.org/home'; }}>
								<Typography variation="button-medium" weight="bold">
									Complete
								</Typography>
							</Button>
						</S.Container>
					);
				} if (validationError != null) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
							{ (() => {
								if (validationError.code === 102) {
									return (
										<>
											<Typography tag="h2" weight="bold" center>
												Your password reset link has expired.
											</Typography>
											<Typography tag="p" className="description" center>
												To reset your password, follow the link below.
											</Typography>
										</>
									);
								}
								return (
									<>
										<Typography tag="h2" weight="bold" center>
											Your reset link has already been used or is no longer valid.
										</Typography>
										<Typography tag="p" className="description" center>
											To reset your password, follow the link below.
										</Typography>
									</>
								);

							})() }
							<Button type="submit" className="submit-button" onClick={() => history.push('/forgot-password')}>
								<Typography variation="button-medium" weight="bold">
									Continue to Forgot Password
								</Typography>
							</Button>
						</S.Container>
					);
				}
				return (
					<S.Container>
						<Typography tag="h2" weight="bold" center>
							Enter New Password
						</Typography>
						<Typography tag="p" className="description" center>
							Enter in a new password.
						</Typography>

						<S.Form onSubmit={onSubmit}>
							<TextInput
								label="New Password"
								type="password"
								id="new-password"
								error={errors.newPassword}
								autoComplete="password"
								{...register('newPassword', {
									required: required('New Password'),
									pattern: pattern('New Password', PASSWORD_REGEX, 'Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long'),
								})}
							/>
							<TextInput
								label="Confirm New Password"
								type="password"
								id="confirm-new-password"
								error={errors.confirmNewPassword}
								autoComplete="password"
								{...register('confirmNewPassword', {
									required: required('Confirm New Password'),
									pattern: pattern('Confirm New Password', PASSWORD_REGEX, 'Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long'),
									validate: {
										equalToPassword: (v) => (getValues('newPassword') === v ? true : "Confirm Password doesn't match with Password"),
									},
								})}
							/>
							<S.ActionsWrapper>
								<Button variant="outline" onClick={() => { window.location = 'https://www.townofmorrisville.org/home'; }}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<Button type="submit">
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading...' : 'Reset'}
									</Typography>
								</Button>
							</S.ActionsWrapper>
						</S.Form>
					</S.Container>
				);
			})()}
		</S.Wrapper>
	);
};

export default ResetPasswordForm;
