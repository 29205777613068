import { createGlobalStyle } from 'styled-components';

import colors from './colors';

const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	html,
	body,
	#root {
		max-height: 100%;
		height: 100%;
	}
	body, button, input, textarea {
		font-family: 'Open Sans', sans-serif;
	}
	h1, h2, h3 {
		font-family: 'Merriweather', serif;
	}
	body {
		background-color: ${colors.neutralBackground};
	}

	.ReactModal {
		&__Overlay {
			opacity: 0;
			transition: opacity 200ms ease-in-out;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 3;

			&--after-open {
				opacity: 1;
			}

			&--before-close {
				opacity: 0;
			}
		}
		&__Content {
			background-color: white !important;
			position: relative !important;
			inset: auto !important;
			cursor: auto;
			display: block;
			align-items: center;
			width: 100%;
			border: none !important;
			border-radius: 0 !important;
			max-width: 72rem; /* 1152px */
			margin: 1rem;
			overflow: visible;
    		max-height: calc(100% - 2rem);
			padding: 0 !important;
			box-shadow: ${colors.boldShadow};

			&.c-message-dialog {
				max-width: 46.875rem;
			}
		}
	}

	.ReactModal__Content ReactModal__Content--after-open {
		overflow: visible;
	}

	.flex-1 {
		flex: 1 1 0%;
	}

	.recharts-legend-item {
		font-size: 0.875rem;
	}

	.allow-box-shadow {
		padding: .5rem;
		margin: -.5rem;
	}

	.free-trial-toast {
		min-width: 45rem;
		background-color: ${colors.stateWarning};

		.toast-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			svg {
				height: .75rem;
			}

			p {
				b {
					padding: 0 .25rem;
				}
			}
		}

		.toast-actions-wrapper {
			display: flex;
			align-items: center;

			.upgrade-button {
				margin-right: .5rem;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}

		@media screen and (max-width: 992px) {
			min-width: 35rem;
		}

		@media screen and (max-width: 768px) {
			min-width: 20rem;
		}
	}

	.table-scrollable-container {
		padding-bottom: 3rem;

		@media screen and (max-width: 992px) {
			padding-bottom: 0;
		}
	}

	.fade-in {
		opacity: 1;
		animation-name: fadeInOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		animation-duration: .33s;
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

export default GlobalStyles;
