import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../services/authService';
import { clearUser } from '../../app/slices/user/userSlice';
import { Spinner } from '../../components';
import * as S from './Logout.styles';
import { META_TITLE } from '../../../constants/general.constants';

const Logout = () => {
	const dispatch = useDispatch();

	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		document.title = `Logout | ${META_TITLE}`;

		let mounted = true;
		logoutUser().then(() => {
			if (mounted) {
				dispatch(clearUser());
				setShouldRedirect(true);
			}
		});
		return () => { mounted = false; };
	}, []);

	const redirect = () => {
		window.location = 'https://www.townofmorrisville.org/home';
	};

	return !shouldRedirect ? (
		<S.Wrapper>
			<Spinner />
		</S.Wrapper>
	) : redirect();
};

export default Logout;
