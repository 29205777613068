import React, { forwardRef, useRef } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import colors from '../../styles/colors';
import * as S from './Select.styles';

const SIZES = {
	small: {
		control: {
			minHeight: 36,
			fontSize: '0.75rem',
		},
		option: {
			fontSize: '0.75rem',
		},
	},
	medium: {
		control: {
			minHeight: 45,
			fontSize: '1rem',
		},
		option: {
			fontSize: '1rem',
		},
	},
};

export const Select = forwardRef(({
	containerClassName, placeholder = '', options = [], label, error, size = 'medium', isCreatable, alternate, transparent, tag, ...rest
}, ref) => {
	const colourStyles = {
		clearIndicator: (styles) => ({ ...styles }),
		container: (styles) => ({ ...styles }),
		control: (styles, { isFocused }) => ({
			...styles,
			borderRadius: '0.25rem',
			minHeight: SIZES[size].control.minHeight,
			fontSize: SIZES[size].control.fontSize,
			borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm,
			boxShadow: 'none',
			'&:hover': { borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm },
			cursor: 'pointer',
		}),
		dropdownIndicator: (styles, { isFocused }) => ({ ...styles, padding: 0, color: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm }),
		group: (styles) => ({ ...styles }),
		groupHeading: (styles) => ({ ...styles }),
		indicatorsContainer: (styles) => ({ ...styles, padding: '0 0.75rem' }),
		indicatorSeparator: (styles) => ({ ...styles, width: 0 }),
		input: (styles) => ({
			...styles, width: '100%', padding: 0, margin: 0
		}),
		loadingIndicator: (styles) => ({ ...styles }),
		loadingMessage: (styles) => ({ ...styles }),
		menu: (styles) => ({ ...styles }),
		menuList: (styles) => ({ ...styles }),
		menuPortal: (styles) => ({ ...styles }),
		multiValue: (styles) => ({ ...styles }),
		multiValueLabel: (styles) => ({ ...styles }),
		multiValueRemove: (styles) => ({ ...styles }),
		noOptionsMessage: (styles) => ({ ...styles }),
		option: (styles) => ({ ...styles, cursor: 'pointer', fontSize: SIZES[size].option.fontSize }),
		placeholder: (styles) => ({
			...styles, padding: 0, margin: 0, whiteSpace: 'nowrap'
		}),
		singleValue: (styles) => ({
			...styles, padding: 0, margin: 0, color: 'black'
		}),
		valueContainer: (styles) => ({ ...styles, padding: '0 0.75rem', margin: 0 }),
	};

	const altStyles = {
		control: (styles, { isFocused }) => ({
			...styles,
			backgroundColor: colors.neutralForm,
			borderRadius: 0,
			minHeight: SIZES[size].control.minHeight,
			fontSize: SIZES[size].control.fontSize,
			borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm,
			boxShadow: 'none',
			'&:hover': { borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm },
			cursor: 'pointer',
		}),
		indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
		dropdownIndicator: (styles, { isFocused }) => ({
			...styles, color: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralText
		}),
		clearIndicator: (styles, { isFocused }) => ({
			...styles, color: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralText
		}),
		multiValue: (styles) => ({
			...styles,
			borderRadius: '500vh',
			border: `2px solid ${colors.brandSecondary}`,
		}),
		multiValueLabel: (styles) => ({ ...styles, color: `${colors.brandSecondary}` }),
		multiValueRemove: (styles) => ({
			...styles,
			'&:hover': {
				backgroundColor: 'transparent', color: `${colors.brandSecondaryHover}`, borderRadius: 0,
			}
		}),
		valueContainer: (styles) => ({
			...styles, padding: '0 0.75rem', margin: 0, color: `${colors.brandSecondary}`
		}),
	};

	const tagStyles = {
		clearIndicator: (styles) => ({ ...styles }),
		container: (styles) => ({ ...styles }),
		control: (styles, { isFocused }) => ({
			...styles,
			borderRadius: '0.25rem',
			minHeight: SIZES[size].control.minHeight,
			fontSize: SIZES[size].control.fontSize,
			borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm,
			boxShadow: 'none',
			'&:hover': { borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm },
			cursor: 'pointer',
		}),
		dropdownIndicator: (styles, { isFocused }) => ({
			...styles, color: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralText
		}),
		indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
		input: (styles) => ({
			...styles, width: '100%', padding: 0, margin: 0
		}),
		loadingIndicator: (styles) => ({ ...styles }),
		loadingMessage: (styles) => ({ ...styles }),
		menu: (styles) => ({ ...styles }),
		menuList: (styles) => ({ ...styles }),
		menuPortal: (styles) => ({ ...styles }),
		multiValue: (styles) => ({
			...styles,
			borderRadius: '500vh',
			border: `2px solid ${colors.brandSecondary}`,
			backgroundColor: 'transparent',
		}),
		multiValueLabel: (styles) => ({ ...styles, color: `${colors.brandSecondary}` }),
		multiValueRemove: (styles) => ({
			...styles,
			'&:hover': {
				backgroundColor: 'transparent', color: `${colors.brandSecondaryHover}`, borderRadius: 0,
			}
		}),
		valueContainer: (styles) => ({
			...styles, padding: '0 0.75rem', margin: 0, color: `${colors.brandSecondary}`, width: '100%'
		}),
	};

	const transparentStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: 'transparent',
			borderRadius: '0.25rem',
			minHeight: SIZES[size].control.minHeight,
			fontSize: SIZES[size].control.fontSize,
			borderColor: error ? colors.stateDanger : 'transparent',
			boxShadow: 'none',
			'&:hover': { borderColor: error ? colors.stateDanger : 'transparent', backgroundColor: colors.neutralBackground },
			cursor: 'pointer',
		}),
		indicatorsContainer: (styles) => ({ ...styles, padding: 0, display: 'none' }),
		dropdownIndicator: (styles) => ({
			...styles, color: error ? colors.stateDanger : 'transparent', display: 'none'
		}),
		clearIndicator: (styles) => ({
			...styles, color: error ? colors.stateDanger : 'transparent',
		}),
		multiValue: (styles) => ({
			...styles, backgroundColor: colors.neutralForm
		}),
		multiValueLabel: (styles) => ({ ...styles, fontSize: '.75rem' }),
		multiValueRemove: (styles) => ({
			...styles, backgroundColor: 'transparent',
		}),
		valueContainer: (styles) => ({
			...styles, padding: 0, margin: 0
		}),
	};

	const selectRef = useRef(null);

	const stylePicker = () => {
		if (alternate) {
			return altStyles;
		} if (transparent) {
			return transparentStyles;
		} if (tag) {
			return tagStyles;
		}
		return colourStyles;

	};

	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="semibold" onClick={() => (ref ? ref.current.focus() : selectRef.current.focus())}>
					{label}
				</Typography>
			)}
			{isCreatable ? (
				<CreatableSelect
					ref={ref || selectRef}
					placeholder={placeholder}
					options={options}
					styles={stylePicker()}
					isMulti
					{...rest}
				/>
			) : <ReactSelect ref={ref || selectRef} placeholder={placeholder} options={options} styles={stylePicker()} {...rest} />}

			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

Select.displayName = 'Select';
Select.propTypes = {
	containerClassName: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
	size: PropTypes.oneOf(['small', 'medium']),
	isCreatable: PropTypes.bool,
	alternate: PropTypes.bool,
	transparent: PropTypes.bool,
	tag: PropTypes.bool,
};
